import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Breadcrumb, Col, Container, Row } from "react-bootstrap"
import settings from "../settings"
import "./city-page.scss"
export default function StatePage({ data }) {
  if (!(data && data.mysqlStates)) {
    return <Layout>Not found</Layout>
  }
  const state = data.mysqlStates
  return (
    <Layout>
      <Container className="statepage">
        <h2 className="align-center">Explore {state.title}</h2>
        <div className="overviewContent">
          <div
            className="align-center"
            dangerouslySetInnerHTML={{
              __html: state.description,
            }}
          ></div>
        </div>
        <Row className="card-container">
          {state.cities.map(city => {
            if (city.thumbImageData !== "") {
              const imageData = JSON.parse(city.thumbImageData)
              return (
                <div
                  className="card"
                  onClick={e => {
                    window.location.href = "/"+ city.slug.toLowerCase()
                  }}
                >
                  <div className="image"  style={{
                    backgroundImage: `url(${settings.IMAGE_URL + imageData.thumbUrl})`
                  }}>
                  <div className="overlay">
                    <p className="subtitle">{city.title}</p>
                  </div>
                  </div>
                </div>
              )
            }
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($uri: String!) {
    mysqlStates(slug: { eq: $uri }) {
      description
      id
      slug
      title
      cities {
        title
        slug
        thumbImageData
      }
    }
  }
`
